.Priv_img {
  width: 100px;
  display: table;
}
.Priv_img img {
  width: 100px;
  position: relative;
}
.remove_img {
  background: #f00;
  position: relative;
  bottom: 22px;
  right: 0;
  border-radius: 50px;
  padding: 2px 5px;
  width: 25px;
  height: 25px;
}
