/* -----inner-admin logo------------ */
.menu_btns {
  display: none;
}
.striking-logo img {
  max-width: 110px !important;
}
/* -----login-screen css------------ */
.login_center {
  background: #e5e5e5;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.login_card {
  background: #fff;
  min-width: 490px;
  padding: 50px;
  border-radius: 10px;
}

.login_card h1 {
  text-align: center;
  color: #333333;
  font-weight: 700;
  padding-bottom: 20px;
}
.login_card input::placeholder {
  color: #808080;
  font-size: 16px;
}

.login_card input {
  color: #808080;
  font-size: 16px;
}

.login_card .forgot-pass-link {
  display: table;
  margin: 30px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #4f35ba;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: revert;
}

.login_logo img {
  width: 200px;
  text-align: center;
  display: table;
  margin: 0px auto 45px;
}
.right_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_profile p {
  margin: 0;
  font-size: 14px;
  font-weight: 900;
}
